import React, { useEffect, useMemo } from 'react';

const endpoints: Record<string, Record<string, string>> = {
  "*": {
    "/": "https://snepicastudios.com",

    "/github": "https://github.com/SnepicaStudios",
    "/linkedin": "https://linkedin.com/company/snepica-studios",
    "/youtube": "https://youtube.com/c/SnepicaStudios",
    "/facebook": "https://facebook.com/SnepicaStudios",
    "/twitter": "https://twitter.com/SnepicaStudios",
  },
  "opendag.multicode.app": {
    "*": "https://multicode.app/opendag"
  }
}

const App: React.FunctionComponent = () => {

  const redirect = useMemo<string | null>(() => {
    const hostname = window.location.hostname
    const pathname = window.location.pathname

    const rootPaths = endpoints["*"]
    const hostPaths = endpoints[hostname]

    let path: string | undefined = undefined
    if (hostPaths !== undefined) {
      path = hostPaths[pathname] || hostPaths["*"]
    }

    return path || rootPaths[pathname] || rootPaths["/"]
  }, [])

  useEffect(() => {
    if (redirect !== null) {
      window.location.href = redirect
    }
  }, [redirect])

  return <div>
    {redirect !== null
      ? <p>Redirecting to <a href={redirect}>{redirect}</a>...</p>
      : <p>No redirect found...</p>
    }
  </div>
}

export default App
